<template>
  <el-table
      :data="tableData"
      style="width: 100%">
    <el-table-column
        prop="companyName"
        label="公司名称"
        width="150">
    </el-table-column>
    <el-table-column
        prop="companyTaxId"
        label="公司税号"
        width="180">
    </el-table-column>
    <el-table-column
        prop="invoiceType"
        label="发票类型"
        width="90">
      <template slot-scope="scope">
        <span style="margin-left: 10px" v-if="scope.row.invoiceType==1">进项发票</span>
        <span style="margin-left: 10px" v-else-if="scope.row.invoiceType==2">销项发票</span>
      </template>
    </el-table-column>
    <el-table-column
        prop="sellerName"
        label="销售方名称"
        width="150">
    </el-table-column>
    <el-table-column
        prop="sellerTaxId"
        label="销售方税号"
        width="150">
      <template slot-scope="scope">
        <el-button class='button'  type="text" @click="jumpTo('/income/invoiceAccount',scope.row.companyId,scope.row.sellerName)">
        {{ scope.row.sellerTaxId }}
        </el-button>
      </template>
    </el-table-column>
      <el-table-column label="当月开票统计" align="center">
        <el-table-column
            prop="totalCount"
            label="发票总张数"
            width="100">
        </el-table-column>
        <el-table-column
            prop="normalCount"
            label="正数发票"
            width="90">
        </el-table-column>
        <el-table-column
            prop="deficitCount"
            label="红字发票"
            width="90">
        </el-table-column>
        <el-table-column
            prop="cancelingCount"
            label="作废发票"
            width="90">
        </el-table-column>
      </el-table-column>
    <el-table-column
        prop="deficitCancelingScale"
        label="红字/作废票比例"
        width="130">
    </el-table-column>
    <el-table-column
        prop="unusualScale"
        label="设置异常比例">
    </el-table-column>
    <el-table-column
        prop="wildScale"
        label="设置失控比例">
    </el-table-column>
    <el-table-column
        prop="comparisonResult"
        label="对比结果">
    </el-table-column>
  </el-table>

</template>

<script>
export default {
  props: {
    tableData:{
      type: Array,
    }
  },
  data() {
    return {}
  },
  methods: {
    jumpTo(url,companyId,sellerName){
      this.$emit("jumpTo",url,companyId,sellerName);
    }
  }
}
</script>